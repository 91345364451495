import React, { Suspense, useEffect, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// ~  Modules  ~ \\
import PopUps from '@modules/popup/Containers'
//import reportWebVitals from '@modules/scripts/reportWebVitals';
import Cursor from '@modules/cursor'
import PreviewGallery from '@modules/gallery/fullpageGallery';
import "@modules/query/query"
import '@modules/variables'

// ~  Style  ~ \\
import '@public/index.css';
import '@style/icons.js';

// ~  App  ~ \\
import Loading from '@modules/loading/loading';
const  Cars = React.lazy(() => import('@/main/App'));
const  Home = React.lazy(() => import('@/main/home'));
const  Admin = React.lazy(() => import('@/admin'));
const  Docs = React.lazy(() => import('@/main/docs'));



function App() {
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(()=>{
    //check size of page and check if is mobile
    window.resize();
    window.EventHandler({event:'resize',el:window,listener:'same'},window.resize,forceUpdate)
  },[])
  
  return (
  <React.StrictMode>
    <PopUps/>
    <PreviewGallery/>
    <Cursor />
    <Suspense fallback={<></>}>
      <Router>
        <Routes>
          {
            //window.isMobile?
            //<Route index element={<Loading to="in-progress" Error="Stránka bude čoskoro dostupná"/>}/>
            //:null
          }
          <Route path="/" element={<Home />}>
              <Route path="dokument">
                <Route path=":name" element={<Docs/>}/>
              </Route>
              <Route path="kategorie" element={<Home/>}/>
              <Route path="cennik" element={<Home/>}/>
              <Route path="dostupnost-vozidiel" element={<Home/>}/>
              <Route path="kontakt" element={<Home/>}/>
          </Route>
          <Route path="in-progress" element={<Loading to="in-progress" Error="Stránka už bude čoskoro dostupná"/>}/>
          <Route path="cars" element={<Cars/>}>
            <Route path=":id" element={<Cars/>} />
          </Route>
          <Route path="category">
            <Route path=":cat" element={<Cars/>}>
              <Route path=":id" element={<Cars/>}/>
            </Route>
          </Route>
          <Route path="*" element={<Admin/>}/>
        </Routes>
      </Router>
    </Suspense>
  </React.StrictMode>
  )
}
ReactDOM.render(<App/>,document.getElementById('root'));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
