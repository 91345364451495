import React, { Component } from 'react' 
import './loading.scss'

class Loading extends Component{
    constructor(){
        super()
        this.state = {
            counter: 100,
            interval: null,
            theme:true
        }
    }
    componentDidMount()
    {
        let {darkTheme} = JSON.parse(localStorage.getItem('theme')) || {};
        if(darkTheme && darkTheme !== this.state.theme)
        {
            this.toggleTheme(darkTheme)
        }
    }
    countdown(){
        let $x = setInterval(()=>{
            if(this.state.counter > 0)
            {
                this.numberDown()
            }
            else
            {
                clearInterval(this.state.interval)
                this.reload()
            }
        },1000)
         
        this.setState({
            interval: $x
        })
    }
    numberDown(){
        let counter = this.state.counter
        counter--
        this.setState({
            counter: counter
        })
    }
    reload()
    {
        let path = this.props.to?this.props.to:"/";
        window.location.assign(path)
    }
    cancel()
    {
        clearInterval(this.state.interval)
        this.props.skipLoad()
    }
    toggleTheme(e)
    {
        this.setState((prevState)=>({
            theme:e?e:!prevState.theme
        }),()=>{
            let theme = JSON.parse(localStorage.getItem('theme')) || {};
            theme.darkTheme = this.state.theme;

            localStorage.setItem('theme',JSON.stringify(theme));
            
        })
    }
    render(){
        const {Error} = this.props
        const {counter,theme} = this.state
        const Theme = theme?"dark":"light";
        if(Error)
        {
            
            if(!this.state.interval)
            {
                this.countdown()
            }
            return(
                <div className={'theme--'+Theme+' Error-page'}>
                    <p className='color'><i mouse="pointer2" className={"gg-"+(theme?"sun":"moon")} onClick={()=>this.toggleTheme()}></i></p>
                    <div className="Error-page">
                        <div className="gg-data"></div>
                        <h1>{Error}</h1>
                        <p>Pokus o znovu načítanie bude automaticky o {counter}s</p>
                        <div className="buttons">
                            <button mouse="pointer2" onClick={()=>this.reload()}>Ihneď znovu načítať</button>
                            {
                                //   <button onClick={()=>this.cancel()}>Zrušiť</button>
                            }
                        </div>
                    </div>
                </div>
            )
        }
        return(
            <div className={'theme--'+Theme+' Loading-page'}>
                <h1>Načítavanie</h1>
                <div className="gg-loadbar"></div>
            </div>
        )
    }
}
export default Loading