
FindObjects(process.env,'react_app_');

function FindObjects(obj,s){
    let returnedObj = {};
    Object.keys(obj).forEach((key)=>{
        let keyName = key.replace(s,'')
        if(key.indexOf(s) !== -1)
        {
            window[keyName] = obj[key]
            returnedObj[keyName] = obj[key]
        }
    })
    return returnedObj
}


window.checkArray = function checkArray(array){ 
    let FullArray = []
    array.map((e,k)=>{
        if(Array.isArray(e))
        {
            return e.map((e,key)=>
                FullArray.push(e)
            )
        }
        return FullArray.push(e);
    })
    return FullArray
}

window.checkJSON = function checkJSON(m) {
    if (typeof m == 'object') { 
       try{ m = JSON.stringify(m); }
       catch(err) { return false; } }
 
    if (typeof m == 'string') {
       try{ m = JSON.parse(m); }
       catch (err) { return false; } }
 
    if (typeof m != 'object') { return false; }
    return true;
 
 }

window.TouchEvent = (el)=>{
    return new TouchEvent.class.Init(el)
}

TouchEvent.class = TouchEvent.prototype = {
    constructor: TouchEvent,
    Init:function(el){
        this.El = el
        this.events = {}
        this.event=(Call=()=>{},endCall=()=>{})=>{
            let handleTouchStart = (e)=>{return TouchEvent.class.TouchStart(this,e);},
                handleTouchMove  = (e)=>{return TouchEvent.class.TouchMove(this,e,Call);},
                handleTouchEnd   = (e)=>{return TouchEvent.class.TouchEnd(this,e,endCall);};
            this.events.start = window.EventHandler({event:'touchstart',el:this.El,listener:'same'},handleTouchStart)
            this.events.move  = window.EventHandler({event:'touchmove',el:this.El,listener:'same'},handleTouchMove)
            this.events.end   = window.EventHandler({event:'touchend',el:this.El,listener:'same'},handleTouchEnd)
            return this;
        }
        this.end=()=>{
            for (let val of Object.values(this.events)) {
                val.endEvent();
            }
        }
        return this;
    },
    TouchStart:(Event,e)=>{
        const firstTouch = e.touches[0];    
        Event.firstTouch={
            clientX:firstTouch.clientX,
            clientY:firstTouch.clientY
        }
        return Event;
    },
    TouchMove:(Event,e,callback)=>{
        let {clientX,clientY} = Event.firstTouch;
        if ( ! clientY || ! clientX ) {
            return;
        }
        let xUp = e.touches[0].clientX;                          
        let yUp = e.touches[0].clientY;
        Event.x = xUp - clientX;
        Event.y = yUp - clientY;
        if(Math.abs(Event.x) >= 30 && Math.abs(Event.y) <= 50)
        {
            Event.scroll = 0;
        }
        else if(Math.abs(Event.y) >= 30){
            Event.scroll = (clientY > yUp)?-1:1;
        }
        else{
            Event.scroll = 0;
        }
        Event.target = e.target;
        callback.call(Event,Event);
        return Event
    },
    TouchEnd:(Event,e,callback)=>{
        if(Event.scroll)
        {
            callback.call(Event,Event);
            delete Event.scroll;
        }
        return Event
    }
}


window.scrollElementIntoView = function(el,position,parentEl=null){
    var to = parseInt(el.offsetTop),
        parent = parentEl?parentEl:window.getScrollParent(el),
        i = parseInt(parent.scrollTop),
        height = parent.scrollHeight - parent.clientHeight;
    if(position === "center")
    {
        to = to / 1.5
    }
    else if(position === "start")
    {
        to = to - 100
    }
    else if(typeof position === "number")
    {
        to = (to + position)
    }
    to = to>height?height:to;
    if ( i !== to ) {
        to = parseInt(to);
        if (i < to) {
            let int = setInterval(function() {
                if (i > (to-20)) i += 1;
                else if (i > (to-40)) i += 3;
                else if (i > (to-80)) i += 8;
                else if (i > (to-160)) i += 18;
                else if (i > (to-200)) i += 24;
                else if (i > (to-300)) i += 40;
                else i += 60;
                window.getScrollParent(el).scroll(0,i);
                if (i >= to) clearInterval(int);
            }, 15);
        }
        else {
            let int = setInterval(function() {
                if (i < (to+20)) i -= 1;
                else if (i < (to+40)) i -= 3;
                else if (i < (to+80)) i -= 8;
                else if (i < (to+160)) i -= 18;
                else if (i < (to+200)) i -= 24;
                else if (i < (to+300)) i -= 40;
                else i -= 60;
                window.getScrollParent(el).scroll(0,i);
                if (i <= to) clearInterval(int);
            }, 15);
        }
        
    }
};

window.getScrollParent = function (node,stop=null) {
    if (node === undefined||node === null || (stop !== null && node === stop)) {
      return null;
    }
  
    if (isScrollable(node)){
      return node;
    } else {
      return window.getScrollParent(node.parentNode);
    }
}

function isScrollable(e){
    if(e.scrollTopMax !== undefined )
        return e.scrollTopMax > 0;

    if( e === document.scrollingElement )
        return e.scrollHeight > e.clientHeight;

    return e.scrollHeight > e.clientHeight && ["scroll", "auto"].indexOf(getComputedStyle(e).overflowY) >= 0
}

window.$ajax = function($data,callback=()=>{},error=()=>{}){
    const data = getProperties($data);
    fetch(window.MainUrl+'/'+data.file+'.php',{
        method:data.method,
        headers: data.headers,
        body:data.body
    })
    .then((e)=>e.json())
    .then((data)=>{
        let $data = data !== null ? data : [];

        if(window.checkJSON($data))
        {
            for(const [key, value] of Object.entries($data))
            {
                if(Array.isArray(value))
                {
                    value.map((e,i)=>{
                        for(const [k, v] of Object.entries(e))
                        {
                            if(window.checkJSON(v))
                            {
                                $data[key][i][k] = JSON.parse(v);
                            }
                        }
                        return null;
                    })  
                }
            }
        }
        callback($data);
    })
    .catch((err)=>{
        error(err);
        console.log(err);
    })
}

function getProperties(e)
{
    const props = {},
        {
            method,
            body,
            file,
            headers
        } = e

        props.method = method || "GET"
        props.body = body || null
        props.headers = headers || {'Accept': 'application/json','Content-Type': 'application/json'}
        props.file = file

    return props;
}


window.strToUrl = (string)=>{
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİľłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiillmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')
  //eslint-disable-next-line
    return string.toString().toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      //eslint-disable-next-line
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      //eslint-disable-next-line
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
}



window.EventHandler = (options,startCall,endCall)=>{
    return new EventHandler(options,startCall,endCall);
}

class EventHandler{
    constructor(options,startCall=()=>{},endCall=()=>{})
    {
        this.options={
            event:options.event,
            el:options.el,
            listener:options.listener === "same"?options.el:options.listener || window
        }
        const {event,el,listener} = this.options
        this.event = this.StartEvent(event,el,startCall,endCall,listener);
        this.endEvent = ()=>{return this.EndEvent()}
        return this;
    }
    StartEvent(event, callback,callback2,endcall,listener){
        let time,eventcall = (e)=>{
            if(typeof callback !== "function" && callback !== undefined)
            {
                let el = callback;
                if(e.target === el || el.contains(e.target))
                {
                    callback2(e,endcall);
                    clearTimeout(time);
                    time = setTimeout(()=>endcall(e,e), 200);
                }
                else
                {
                    for (let ele of el.children){
                        if(e.target === ele)
                        {
                            callback2.call(e,endcall);
                            clearTimeout(time);
                            time = setTimeout(()=>endcall(e,e), 200);
                        }
                        else
                        {
                            clearTimeout(time);
                            time = setTimeout(()=>endcall(e,e), 200);
                        }
                    }
                }
            }
            else
            {
                callback.call(e,e);
            }
          };
           listener.addEventListener(event,eventcall, false);
          
          return eventcall
    }
    EndEvent(){
        const {listener,event:eventName} = this.options;
        listener.removeEventListener(eventName,this.event);
        this.event = null;
        return this
    }
}


window.resize = (e,c)=>{
    let head = window.Q('html');
    head.setCss({
        "--vph":window.innerHeight+"px",
        "--vpw":window.innerWidth+'px'
    })
    if(window.innerWidth < 920)
    {
        if(!window.isMobile)
        {
            window.isMobile = true;
            c();
        }
    }
    else
    {   
        if(window.isMobile)
        {
            window.isMobile = false;
            c();
        }
    }
}


window.compareObj = (a,b)=>{
    return (JSON.stringify(a) === JSON.stringify(b))
}

window.copyObj = (e)=>{
    return JSON.parse(JSON.stringify(e))
}