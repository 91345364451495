// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{margin:0;font-family:\"Source Sans 3\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",\"Roboto\",\"Oxygen\",\"Ubuntu\",\"Cantarell\",\"Fira Sans\",\"Droid Sans\",\"Helvetica Neue\",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;overflow:hidden;width:100vw;height:100vh}code{font-family:source-code-pro,Menlo,Monaco,Consolas,\"Courier New\",monospace}#root{width:100%;height:100%}a{color:#000;margin:0 5px;cursor:pointer;text-decoration:none}.main.error{display:flex;flex-direction:column;justify-content:center;align-items:center;width:100vw;height:100vh;background-color:#e1e1e1}", "",{"version":3,"sources":["webpack://./App/public/index.css"],"names":[],"mappings":"AAAA,KAAK,QAAQ,CAAC,mKAAmK,CAAC,kCAAkC,CAAC,iCAAiC,CAAC,eAAe,CAAC,WAAW,CAAC,YAAY,CAAC,KAAK,yEAAyE,CAAC,MAAM,UAAU,CAAC,WAAW,CAAC,EAAE,UAAU,CAAC,YAAY,CAAC,cAAc,CAAC,oBAAoB,CAAC,YAAY,YAAY,CAAC,qBAAqB,CAAC,sBAAsB,CAAC,kBAAkB,CAAC,WAAW,CAAC,YAAY,CAAC,wBAAwB","sourcesContent":["body{margin:0;font-family:\"Source Sans 3\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",\"Roboto\",\"Oxygen\",\"Ubuntu\",\"Cantarell\",\"Fira Sans\",\"Droid Sans\",\"Helvetica Neue\",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;overflow:hidden;width:100vw;height:100vh}code{font-family:source-code-pro,Menlo,Monaco,Consolas,\"Courier New\",monospace}#root{width:100%;height:100%}a{color:#000;margin:0 5px;cursor:pointer;text-decoration:none}.main.error{display:flex;flex-direction:column;justify-content:center;align-items:center;width:100vw;height:100vh;background-color:#e1e1e1}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root",
	"main": "main",
	"error": "error"
};
export default ___CSS_LOADER_EXPORT___;
