function CreateGallery() {
    this.add = () => {};
    this.close = () => {};

    this.addGallery = (images,key) => {
      return this.add(images,key);
    };
  
    this.declare = ({ addGallery, closeGallery }) => {
      this.add   = addGallery;
      this.close = closeGallery;
    };
  
    return this;
  }
  
  export default new CreateGallery();