import React, { Component } from 'react'
import PopUp from './PopUp.jsx'
import PopUps from './index.jsx'
import './style.scoped.css'

class PopUpsContainers extends Component{
    constructor(){
        super()
        this.state = {
            PopUps : {
                Left:[],
                Center:[],
                Right:[]
            }
        }
        this.add = this.add.bind(this)
        this.remove = this.remove.bind(this)
        this.removeByid = this.removeByid.bind(this)
    }
    componentDidMount() {
        PopUps.declare({
          addPopUp: this.add,
          removePopUp: this.removeByid
        });
    }

    add(PopUp)
    {
        const {PopUps} = this.state
        const {replace, container , id} = PopUp
        const Array = PopUps[container];
        if(replace)
        {
            let Item = Array.find(element => element.replace === true)
            if(Item)
            {
                let i = Array.indexOf(Item)
                Array[i].Hide = true;
                this.setState(prevState => ({
                    PopUps: {
                        ...prevState.PopUps
                    }
                }))
                setTimeout(()=>{
                    Array[i] = PopUp
                    this.setState(prevState => ({
                        PopUps: {
                            ...prevState.PopUps
                        }
                    }))
                },sToTime(Array[i].animationEndDuration))

                return {id,container}
            }
            else
            {
                Array.push(PopUp)
            }
        }
        else
        {
            Array.push(PopUp)
        }
        
        this.setState(prevState => ({
            PopUps: {
                ...prevState.PopUps
            }
        }))
        return {id,container}
    }
    removeByid(Element)
    {
        const {id,container} = Element,
              {PopUps} = this.state,
              Elements = PopUps[container]
        let Item = Elements.find(element => element.id === id)
        if(Item)
        {
            let i = Elements.indexOf(Item)
            Elements[i].Remove = true;
            this.setState(prevState => ({
                PopUps: {
                    ...prevState.PopUps
                }
            }))
        }
    }
    remove(PopUp,el)
    {
        const {id,container} = PopUp,
              {PopUps} = this.state,
              Elements = PopUps[container]

            el.children[0].removeAttribute('style')
            el.classList.remove('hide')
            this.setState(prevState => ({
                PopUps: {
                    ...prevState.PopUps,
                    [container]:Elements.filter(({ id: EId }) => EId !== id)
                }
            }))
    }

    renderPopUps(PopUps)
    {
        return PopUps.map((popUp) => (
            <PopUp onRemove={(e,el) => this.remove(e,el)} key={popUp.id} {...popUp}/>
        ))
    }

    render()
    {
        const {PopUps} = this.state,
              PopupsLeft = this.renderPopUps(PopUps.Left),
              PopupsCenter = this.renderPopUps(PopUps.Center),
              PopupsRight = this.renderPopUps(PopUps.Right)
        return(
            <div className="PopUps_Wrapper">
                <div className="PopUps left">{PopupsLeft}</div>
                <div className="PopUps center">{PopupsCenter}</div>
                <div className="PopUps right">{PopupsRight}</div>
            </div>
        )
    }
}



export default PopUpsContainers

function sToTime(s)
{
    let $x = Number(s.replace(/[^0-9.-]+/g,""))
    $x = ($x % 100) * 1000
    return $x

}