import {Component,createRef} from 'react'
import gsap,{Power2} from "gsap";
import './style.scoped.scss'

class Cursor extends Component{
    constructor(){
        super()
        this.cursor = createRef();
    }
    componentDidMount(){
        if(!window.isMobile)
        {
            if(window.CursorEvent === undefined)
            {
                window.CursorEvent = CursorEvent(this.cursor);
            }
            else
            {
                window.CursorEvent.end();
                window.CursorEvent = undefined;
            }
        }
    }
    componentWillUnmount(){
        if(!window.isMobile)
        {
            window.CursorEvent.end();
            window.CursorEvent = undefined;
        }
    }
    render(){
        if(window.isMobile)
        {
            return (<></>);
        }
        return(
            <div cursor="wrap" ref={this.cursor}>
                <div cursor="circle"></div>
                <div cursor="el"></div>
            </div>
        )
    }
}

export default Cursor

var CursorEvent = function(el){
    if(el)
    {
        return new CursorEvent.class.init(el)
    }
    return;
}

CursorEvent.class = CursorEvent.prototype = {
    constructor: CursorEvent,
    init: function(ref){
        this.wrap = ref.current;
        this.el = this.wrap.children[0];
        this.child = this.wrap.children[1];
        this.events = [];
        this.event = function(callback,callback2,endcall=()=>{}){
           return CursorEvent.class.StartEvent(this,callback,callback2,endcall);
        };
        this.end = function(){
            return CursorEvent.class.end(this);
        }
        CursorEvent.class.Start(this);
        return this
    },
    Start:function (event) {
        let CursorEvent = (e)=>
        {
                let el = e.toElement || e.relatedTarget;

                //if(e.target.getAttribute('mouse') === 'button' || ( el!== null && el.parentNode.getAttribute('mouse') === "button"))
                //{
                //    return;
                //}
                if(e.target.getAttribute('mouse') === 'pointer' || (el!== null && el.parentNode.getAttribute('mouse') === 'pointer') || (el!== null && el.getAttribute('mouse') === 'pointer') || hasParentAttr(e.target,'pointer','mouse'))
                {
                    event.wrap.classList.add("active");
                }
                else
                { 
                    event.wrap.classList.remove("active");
                }
                if(e.target.getAttribute('mouse') === 'pointer2' || (el!== null && el.parentNode.getAttribute('mouse') === 'pointer2') || (el!== null && el.getAttribute('mouse') === 'pointer2') || hasParentAttr(e.target,'pointer2','mouse'))
                {
                    event.wrap.classList.add("pointer");
                }
                else
                { 
                    event.wrap.classList.remove("pointer");
                }
                
                gsap.to(event.child,{
                    duration:0.35,
                    left: e.clientX,
                    top: e.clientY,
                    ease:Power2.easeOut
                });
                
                gsap.to(event.el,{
                    duration:0.45,
                    left: e.clientX,
                    top: e.clientY,
                    ease:Power2.easeOut
                });
        };
        event.events.push(CursorEvent);
        window.addEventListener('mousemove',CursorEvent,false);
    },
    StartEvent: function(event, callback,callback2,endcall){
        let eventcall = (e)=>{
            if(typeof callback !== "function" && callback !== undefined)
            {
                let el = callback;
                if(e.target === el)
                {
                  callback2.call(e,e);
                }
                else
                {
                    for (let ell of el.children){
                        if(e.target === ell)
                        {
                            callback2.call(e,e);
                        }
                        else
                        {
                            endcall.call(e,e)
                        }
                    }
                }
            }
            else
            {
                callback.call(e,e);
            }
          };
              
          window.addEventListener('mousemove',eventcall, false);
          
          return eventcall

    },
    end: function(event)
    {
        event.events.map((e)=>{
            window.removeEventListener('mousemove',e);
            return null;
        })
    }
}

function hasParentAttr(element, classname, attr) {
    if(element!== null){
        if (typeof element.getAttribute === "function" &&element.getAttribute(attr) === classname) return true;
        return hasParentAttr(element.parentNode, classname, attr);
    }
    return false;
}