import { Component, createRef } from 'react'
import CreateGallery from './index.jsx'
import './style.scss'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default class FullPageGallery extends Component{
    constructor(){
        super()
        this.state = {
            preview:false,
            imageKey:null,
            data: []
        }
        this.galleryPreviewWrap = createRef(this)
        this.add = this.add.bind(this)
        this.close = this.close.bind(this)
    }
    renderImages()
    {
        const {imageKey,data} = this.state

        return data.map((e,k)=>(
            <div className={"image"+(imageKey===k?' active':'')} mouse="pointer2" key={k} onClick={()=>this.showImg(k)}>
                <LazyLoadImage src={e.src} alt={e.desc} effect="blur"/>
            </div>
        ))
    }
    componentDidMount()
    {
        CreateGallery.declare({
            addGallery: this.add,
            closeGallery: this.close
        })
        this.whell = (e)=>this.onScroll(e,this);
        this.keyUp = (e)=>this.onKeyUp(e,this);
        this.galleryPreviewWrap.current.addEventListener("wheel", this.whell,{passive: true});
        window.addEventListener("keyup", this.keyUp);
    }
    componentWillUnmount()
    {
        this.galleryPreviewWrap.current.removeEventListener('wheel',this.whell);
        window.removeEventListener("keyup",this.keyUp);
    }
    onScroll(evt,gallery)
    {
        //evt.preventDefault();
        gallery.galleryPreviewWrap.current.scrollLeft += evt.deltaY;
        
    }
    onKeyUp(evt,gallery)
    {
        const {imageKey} = gallery.state;
        if(imageKey === null) return;
        if(evt.keyCode === 37)
        {
            gallery.keyChange(imageKey-1);
        }
        else if(evt.keyCode === 39)
        {
            gallery.keyChange(imageKey+1);
        }
    }
    showImg(k)
    {
        this.setState({
            imageKey:k
        })
    }
    keyChange(e)
    {
        const {data} = this.state;
        if((data.length-1) < e)
        {
            this.setState({
                imageKey:0
            })
            return;
        }
        else if(e<0){
            this.setState({
                imageKey:data.length-1
            })
            return;
        }
        this.setState({
            imageKey:e
        })
    }
    close()
    {
        this.setState({
            preview:false,
            imageKey:null
        })
    }
    add(e,k)
    {
        this.setState({
            preview:true,
            imageKey:k,
            data:e
        })
    }
    render()
    {
        const Images = this.renderImages(),
              {data} = this.state,
              {preview,imageKey}=this.state
        return(
            <div className={"gallery-preview"+(preview?' active':'')}> 
                <div className="image-preview">
                    <div className="slider-arrow" mouse="pointer" onClick={()=>this.keyChange((imageKey-1))}><i className="arrow gg-arrow-left"></i></div>
                        {
                            imageKey!=null?
                                <LazyLoadImage src={data[imageKey].src} alt={data[imageKey].desc} effect="blur"/>
                            :null
                        }
                    <div className="slider-arrow" mouse="pointer" onClick={()=>this.keyChange((imageKey+1))}><i className="arrow gg-arrow-right"></i></div>
                </div>
                <div className="gallery-in-preview" ref={this.galleryPreviewWrap}>
                    {Images}
                </div>
                <span className="close-preview" mouse="pointer" onClick={()=>this.close()}><i className="gg-close"></i></span>
                <div className="gallery-bg" onClick={()=>this.close()}></div>
            </div>
        )
    }
}